import api from './api';

class LayoutService {
  
  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/layouts${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/layouts/${id}`);
  }

  newItem(params) {
    return api.post(`/api/layouts/`, params)
  }

  editItem(params) {
    return api.put(`/api/layouts/${params.id}`, params)
  }
}

export default new LayoutService();