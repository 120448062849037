<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Edición de la variable {{ variable.nombre }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="variable.nombre"
                label="Nombre de la variable"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="variable.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="variable.layout_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingCampos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingCampos"
                v-model="variable.campo_id"
                :items="campos"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Campo"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <CButton
                v-if="!loadingSave"
                color="primary"
                class="px-4"
                @click="agregarTransformacion()"
                >+ Transformación</CButton
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="loadingTransformaciones">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-data-table
                v-if="!loadingTransformaciones"
                :headers="headers"
                :items="transformacionesArray"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-slot:item.numero="{ item }">
                  {{ transformacionesArray.indexOf(item) + 1 }}
                </template>

                <template v-slot:item.transformacion="props">
                  <v-select
                    v-if="!loadingCampos"
                    v-model="props.item.transformacion"
                    :items="transformaciones"
                    :item-text="'valor_texto'"
                    :item-value="'abreviatura'"
                    attach
                    label="Transformación"
                  ></v-select>
                </template>

                <template v-slot:item.valorA="props">
                  <v-text-field
                    v-model="props.item.valorA"
                    label="Valor A"
                    single-line
                    counter
                    hide-details="auto"
                  ></v-text-field>
                </template>

                <template v-slot:item.valorB="props">
                  <v-text-field
                    v-model="props.item.valorB"
                    label="Valor B"
                    single-line
                    counter
                    hide-details="auto"
                  ></v-text-field>
                </template>

                <template v-slot:item.campoC="props">
                  <div
                    v-if="loadingCampos"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="!loadingCampos"
                    v-model="props.item.campoC"
                    :items="campos"
                    :item-text="'nombre'"
                    :item-value="'nombre'"
                    attach
                    label="Campo C"
                  ></v-select>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="variable.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
      <!-- <pre>{{variable}}</pre> -->
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VariableService from "@/services/variable.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      variable: {
        nombre: undefined,
        descripcion: undefined,
        layout_id: undefined,
        campo_id: undefined,
        transformaciones: [],
        activo: 1,
      },
      transformacionesArray: [],
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 2) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      ordenRules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 1) || "Min 1 caracteres",
      ],
      fuentes: [],
      campos: [],
      transformaciones: [],
      headers: [
        { text: "Numero", value: "numero", sortable: false },
        {
          text: "Transformación",
          value: "transformacion",
          sortable: false,
        },
        { text: "Valor A", value: "valorA", sortable: false },
        { text: "Valor B", value: "valorB", sortable: false },
        { text: "Campo C", value: "campoC", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      loading: false,
      loadingLayouts: false,
      loadingTransformaciones: false,
      loadingCampos: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "variable.layout_id"(newVal) {
      this.fetchCampos("");
    },
  },
  mounted() {
    if (!this.permissions.includes("Definición de variables"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_variable")) {
      this.variable = JSON.parse(localStorage.getItem("datos_variable"));
      this.transformacionesArray = JSON.parse(this.variable.transformaciones);
    }

    this.fetchLayouts("");
    this.fetchTransformaciones("");
  },
  methods: {
    save() {
      console.log("save--");
      //inicio limpio transformaciones vacias
      this.transformacionesArray.forEach(function(item, index, object){
        console.log(item);
        if(item.transformacion == ''){
          object.splice(index);
        }
      });
      //fin limpio tranformaciones vacias
      this.variable.transformaciones = JSON.stringify(
        this.transformacionesArray
      );
      this.loadingSave = true;
      console.log(this.variable);
      if (this.variable.hasOwnProperty("id")) {
        VariableService.editItem(this.variable.layout_id, this.variable).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/variables` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        VariableService.newItem(this.variable.layout_id, this.variable).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/variables` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchTransformaciones(cadenaBusqueda) {
      this.loadingTransformaciones = true;
      // ListaService.getItems(5, cadenaBusqueda).then(
        if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      ListaService.getTransformaciones(cadenaBusqueda).then(
        (response) => {
          console.log('transformaciones...')
          console.log(response)
          this.transformaciones = response.data.data;
          this.loadingTransformaciones = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTransformaciones = false;
        }
      );
    },
    fetchCampos(cadenaBusqueda) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      CampoService.getItems(this.variable.layout_id, cadenaBusqueda).then(
        (response) => {
          this.campos = response.data;
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    agregarTransformacion() {
      this.transformacionesArray.push({
        transformacion: "",
        valorA: "",
        valorB: "",
      });
    },
    deleteItem(transformacion) {
      let i = this.transformacionesArray.indexOf(transformacion);
      this.transformacionesArray.splice(i, 1);
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/variables" });
    },
  },
};
</script>

<style>
 .elevation-1>.v-data-table__wrapper{
   min-height: 28em;
 }
</style>
